import { createContext } from 'react'
import { BonusFiltersState } from './BonusFiltersProvider'

interface ContextProps {
    bonusFilters: BonusFiltersState

    updateSearchFilter: (value) => void
    updateStatusFilter: (value) => void
    updateRepresentativeFilter: (value) => void
    updateSupervisorFilter: (value) => void
    updateDistributorFilter: (value) => void
    updateCommercialFilter: (value) => void
    updateStartDateFilter: (value) => void
    updateEndDateFilter: (value) => void
    updateAvailableFilter: (value) => void
    resetState: () => void
}

export const BonusFiltersContext = createContext({} as ContextProps)
