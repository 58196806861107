import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { ChakraProvider } from '@chakra-ui/react'
import 'tailwindcss/tailwind.css'

import theme from '@/themes/theme'
import PrivateRoute from '@/components/utils/PrivateRoute'
import { Mantenimiento } from '@/components/utils/Mantenimiento'
import { FiltersProvider } from '@/context/filters'
import { BonusFiltersProvider } from '@/context/bonusFilters'
import { TaskFiltersProvider } from '@/context/taskFilters'
import { SolutionsFiltersProvider } from '@/context/solutionsFilters'
import { RemittancesFiltersProvider } from '@/context/remittancesFilters.tsx'
import { ClientsFiltersProvider } from '@/context/clientsFilters'
import { PROTECTED_ROUTES } from '@/utils/routes'
import { DistributorsFiltersProvider } from '@/context/distributorsFilters'
import { CampaignsFiltersProvider } from '@/context/campaignsFilters'

const App: NextPage<AppProps> = ({ Component, pageProps }) => {
    return (
        <ChakraProvider theme={theme}>
            <FiltersProvider>
                <ClientsFiltersProvider>
                    <BonusFiltersProvider>
                        <SolutionsFiltersProvider>
                            <DistributorsFiltersProvider>
                                <CampaignsFiltersProvider>
                                    <TaskFiltersProvider>
                                        <RemittancesFiltersProvider>
                                            {/* <Mantenimiento /> */}
                                            <PrivateRoute
                                                protectedRoutes={
                                                    PROTECTED_ROUTES
                                                }>
                                                <Component {...pageProps} />
                                            </PrivateRoute>
                                        </RemittancesFiltersProvider>
                                    </TaskFiltersProvider>
                                </CampaignsFiltersProvider>
                            </DistributorsFiltersProvider>
                        </SolutionsFiltersProvider>
                    </BonusFiltersProvider>
                </ClientsFiltersProvider>
            </FiltersProvider>
        </ChakraProvider>
    )
}

export default App
