import { SolutionsFiltersState } from './'

type FiltersActionType =
    | { type: '[SOLUTIONS_FILTERS] UPDATE_SEARCH'; payload: { search: string } }
    | { type: '[SOLUTIONS_FILTERS] UPDATE_STATUS'; payload: { status: string } }
    | {
          type: '[SOLUTIONS_FILTERS] UPDATE_STATUS_IMPLEMENTATION'
          payload: { statusImplementation: string }
      }
    | {
          type: '[SOLUTIONS_FILTERS] UPDATE_START_DATE'
          payload: { start_date: string }
      }
    | {
          type: '[SOLUTIONS_FILTERS] UPDATE_END_DATE'
          payload: { end_date: string }
      }
    | { type: '[SOLUTIONS_FILTERS] RESET_STATE' }

export const solutionsFiltersReducer = (
    state: SolutionsFiltersState,
    action: FiltersActionType,
): SolutionsFiltersState => {
    switch (action.type) {
        case '[SOLUTIONS_FILTERS] UPDATE_SEARCH': {
            return {
                ...state,
                search: action.payload.search,
            }
        }

        case '[SOLUTIONS_FILTERS] UPDATE_STATUS': {
            return {
                ...state,
                status: action.payload.status,
            }
        }

        case '[SOLUTIONS_FILTERS] UPDATE_STATUS_IMPLEMENTATION': {
            return {
                ...state,
                statusImplementation: action.payload.statusImplementation,
            }
        }

        case '[SOLUTIONS_FILTERS] UPDATE_START_DATE': {
            return {
                ...state,
                start_date: action.payload.start_date,
            }
        }

        case '[SOLUTIONS_FILTERS] UPDATE_END_DATE': {
            return {
                ...state,
                end_date: action.payload.end_date,
            }
        }

        case '[SOLUTIONS_FILTERS] RESET_STATE': {
            return {
                search: '',
                status: '',
                statusImplementation: '',
                end_date: '',
                start_date: '',
            }
        }

        default:
            return state
    }
}
