import { theme } from '../../tailwind.config'

export const colors = theme.extend.colors

export const validateDNI = (dni: string) => {
    let letter = ''
    let letterShort = ''
    let numberStr = ''
    let numberShort = 0
    const regex = /^[XYZ]?\d{5,8}[A-Z]$/

    dni = dni.toUpperCase()

    if (regex.test(dni) === true) {
        numberStr = dni.slice(0, dni.length - 1)
        numberStr = numberStr.replace('X', '0')
        numberStr = numberStr.replace('Y', '1')
        numberStr = numberStr.replace('Z', '2')
        letterShort = dni.slice(dni.length - 1)

        numberShort = parseInt(numberStr) % 23

        letter = 'TRWAGMYFPDXBNJZSQVHLCKET'
        letter = letter.substring(numberShort, numberShort + 1)
        if (letter != letterShort) {
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}

export const validateCIF = (cif: string) => {
    if (!cif || cif.length !== 9) {
        return false
    }

    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
    const digits = cif.slice(1, cif.length - 1)
    const letter = cif.slice(0, 1)
    const control = cif.slice(cif.length - 1)
    let sum = 0
    let digit = 0
    let i = 0

    if (!letter.match(/[A-Z]/)) {
        return false
    }

    for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i])

        if (isNaN(digit)) {
            return false
        }

        if (i % 2 === 0) {
            digit *= 2
            if (digit > 9) {
                // @ts-ignore
                digit = parseInt(digit / 10) + (digit % 10)
            }

            sum += digit
        } else {
            sum += digit
        }
    }

    sum %= 10
    if (sum !== 0) {
        digit = 10 - sum
    } else {
        digit = sum
    }

    if (letter.match(/[ABEH]/)) {
        return String(digit) === control
    }
    if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control
    }

    return String(digit) === control || letters[digit] === control
}

export const remittancesStatus = [
    { id: 1, name: 'draft', label: 'Borrador' },
    { id: 2, name: 'sent', label: 'Enviado' },
    { id: 3, name: 'generating', label: 'Generando' },
]

export const PAYMENT_SEPA = 1
export const PAYMENT_BANK_TRANSFER = 3
export const PAYMENT_TDC_DEFERRED = 6
export const BONUS_REJECTED_ID = 11
export const IVA_EXEMPT = '0.00'
export const FLESIP_WEB_ID = 12
export const FLESIP_ID = 4
export const FLESIP_JUST_1 = 'flesip-just-1.pdf'

export const LAPTOP_AUTHORIZATION = 'solutions-laptop-authorization'

export const FLESIP_ALIAS = 'flesip'
export const FLESIP_WEB_ALIAS = 'flesip-web'
export const RRSS_ALIAS = 'rrss'
export const BUSINESS_ALIAS = 'metabase'
export const CRM_ALIAS = 'crm'

export const CANARIAS_VALUE = 'canarias'

export const PAYMENTS_METHODS = {
    sepa: { id: 1, name: 'sepa', Label: 'Sepa' },
    creditCard: { id: 2, name: 'credit_card', Label: 'Tarjeta de crédito' },
    bankTransfer: {
        id: 3,
        name: 'bank_transfer',
        Label: 'Transferencia bancaria',
    },
    tpv: { id: 4, name: 'tpv', Label: 'TPV Físico Leopoldo' },
    bizum: { id: 5, name: 'bizum', Label: 'Bizum' },
    creditCardDelayed: {
        id: 6,
        name: 'credit_card_delayed',
        Label: 'Tarjeta diferida',
    },
}

export const AGENTS = {
    atrinium: {
        id: 1,
        value: 'atrinium',
        label: 'ATRINIUM, S.L',
        dni: 'B93391878',
        administrator: 'JOSE LUIS MUÑOZ ROBLES',
    },
    canarias: {
        id: 2,
        value: CANARIAS_VALUE,
        label: 'WAKARI SOLUTIONS, S.L.',
        dni: 'B93680429',
        administrator: 'JOSE JULIO MATARIN REQUENA',
    },
}

export const JIRA_USERS = {
    '624b0645699649006ae94333': {
        id: '624b0645699649006ae94333',
        name: 'Diego Rojas Placencia',
    },
    '557058:52803af8-f2d9-4baf-ba08-5233bd1f9bfe': {
        id: '557058:52803af8-f2d9-4baf-ba08-5233bd1f9bfe',
        name: 'Jose Luis Muñoz',
    },
    '627378156a38370069de980f': {
        id: '627378156a38370069de980f',
        name: 'Maria Alejandra Segura',
    },
    '64258bb91273131f2ae3a868': {
        id: '64258bb91273131f2ae3a868',
        name: 'Juan Diego Menacho',
    },
    '62c0964b9e6ba34c99389494': {
        id: '62c0964b9e6ba34c99389494',
        name: 'Cristina Ullah',
    },
    '62df7eb4b6b0b70770d7cfcb': {
        id: '62df7eb4b6b0b70770d7cfcb',
        name: 'Cruz Valerio',
    },
    '62df7ea65f7842fb1293bb9d': {
        id: '62df7ea65f7842fb1293bb9d',
        name: 'Freddy Campos',
    },
    '63960e81ff36874b23af461f': {
        id: '63960e81ff36874b23af461f',
        name: 'Kit Digital',
    },
    '6278ce00a20bd0006fd9071d': {
        id: '6278ce00a20bd0006fd9071d',
        name: 'Victor Maza',
    },
    '63dcde4f82eeee78a4cf92fc': {
        id: '63dcde4f82eeee78a4cf92fc',
        name: 'Melissa Silva',
    },
    '63dcde4f724a5c79c7b90086': {
        id: '63dcde4f724a5c79c7b90086',
        name: 'Mara Bastidas Aquino',
    },
}

export const ROLES = {
    comercial: {
        name: 'comercial',
    },
    admin: {
        name: 'admin',
    },
    administrative: {
        name: 'administrative',
    },
}
