import { createContext } from 'react'
import { RemittancesFiltersState } from './RemittancesFiltersProvider'

interface ContextProps {
    remittancesFilters: RemittancesFiltersState

    updateSearchFilter: (value) => void
    updateStatusFilter: (value) => void
    updateStartDateFilter: (value) => void
    updateEndDateFilter: (value) => void
    resetState: () => void
}

export const RemittancesFiltersContext = createContext({} as ContextProps)
