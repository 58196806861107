import { ClientsFiltersState } from '.'

type FiltersActionType = {
    type: '[CLIENTS_FILTERS] UPDATE_SEARCH'
    payload: { search: string }
}

export const clientsFiltersReducer = (
    state: ClientsFiltersState,
    action: FiltersActionType,
): ClientsFiltersState => {
    switch (action.type) {
        case '[CLIENTS_FILTERS] UPDATE_SEARCH': {
            return {
                ...state,
                search: action.payload.search,
            }
        }

        default:
            return state
    }
}
