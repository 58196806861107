import React, { FC, useReducer, ReactNode, useEffect } from 'react'

import { DistributorsFiltersContext, distributorsFiltersReducer } from '.'

export interface DistributorsFiltersState {
    search: string
}

interface Props {
    children: ReactNode
}

const DistributorsFilters_INITIAL_STATE: DistributorsFiltersState = {
    search: '',
}

export const DistributorsFiltersProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(
        distributorsFiltersReducer,
        DistributorsFilters_INITIAL_STATE,
    )

    const updateSearchFilter = (value: string) => {
        dispatch({
            type: '[DISTRIBUTORS_FILTERS] UPDATE_SEARCH',
            payload: { search: value },
        })
    }

    const resetState = () => {
        dispatch({
            type: '[DISTRIBUTORS_FILTERS] RESET_STATE',
        })
    }

    return (
        <DistributorsFiltersContext.Provider
            value={{
                distributorsFilters: { ...state },

                // Methods
                updateSearchFilter,
                resetState,
            }}>
            {children}
        </DistributorsFiltersContext.Provider>
    )
}
