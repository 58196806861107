import { RemittancesFiltersState } from './'

type FiltersActionType =
    | {
          type: '[REMITTANCES_FILTERS] UPDATE_SEARCH'
          payload: { search: string }
      }
    | {
          type: '[REMITTANCES_FILTERS] UPDATE_STATUS'
          payload: { status: string }
      }
    | {
          type: '[REMITTANCES_FILTERS] UPDATE_START_DATE'
          payload: { start_date: string }
      }
    | {
          type: '[REMITTANCES_FILTERS] UPDATE_END_DATE'
          payload: { end_date: string }
      }
    | { type: '[REMITTANCES_FILTERS] RESET_STATE' }

export const remittancesFiltersReducer = (
    state: RemittancesFiltersState,
    action: FiltersActionType,
): RemittancesFiltersState => {
    switch (action.type) {
        case '[REMITTANCES_FILTERS] UPDATE_SEARCH': {
            return {
                ...state,
                search: action.payload.search,
            }
        }

        case '[REMITTANCES_FILTERS] UPDATE_STATUS': {
            return {
                ...state,
                status: action.payload.status,
            }
        }

        case '[REMITTANCES_FILTERS] UPDATE_START_DATE': {
            return {
                ...state,
                start_date: action.payload.start_date,
            }
        }

        case '[REMITTANCES_FILTERS] UPDATE_END_DATE': {
            return {
                ...state,
                end_date: action.payload.end_date,
            }
        }

        case '[REMITTANCES_FILTERS] RESET_STATE': {
            return {
                search: '',
                status: '',
                end_date: '',
                start_date: '',
            }
        }

        default:
            return state
    }
}
