import React, { FC, useReducer, ReactNode } from 'react'

import { SolutionsFiltersContext, solutionsFiltersReducer } from './'

export interface SolutionsFiltersState {
    search: string
    status: string
    statusImplementation: string
    start_date: string
    end_date: string
}

interface Props {
    children: ReactNode
}

const SolutionsFilters_INITIAL_STATE: SolutionsFiltersState = {
    search: '',
    status: '',
    statusImplementation: '',
    start_date: '',
    end_date: '',
}

export const SolutionsFiltersProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(
        solutionsFiltersReducer,
        SolutionsFilters_INITIAL_STATE,
    )

    const updateSearchFilter = (value: string) => {
        dispatch({
            type: '[SOLUTIONS_FILTERS] UPDATE_SEARCH',
            payload: { search: value },
        })
    }

    const updateStatusFilter = (value: string) => {
        dispatch({
            type: '[SOLUTIONS_FILTERS] UPDATE_STATUS',
            payload: { status: value },
        })
    }

    const updateStatusImplementationFilter = (value: string) => {
        dispatch({
            type: '[SOLUTIONS_FILTERS] UPDATE_STATUS_IMPLEMENTATION',
            payload: { statusImplementation: value },
        })
    }

    const updateStartDateFilter = (value: string) => {
        dispatch({
            type: '[SOLUTIONS_FILTERS] UPDATE_START_DATE',
            payload: { start_date: value },
        })
    }

    const updateEndDateFilter = (value: string) => {
        dispatch({
            type: '[SOLUTIONS_FILTERS] UPDATE_END_DATE',
            payload: { end_date: value },
        })
    }

    const resetState = () => {
        dispatch({
            type: '[SOLUTIONS_FILTERS] RESET_STATE',
        })
    }

    return (
        <SolutionsFiltersContext.Provider
            value={{
                solutionsFilters: { ...state },

                // Methods
                updateSearchFilter,
                updateStatusFilter,
                updateStatusImplementationFilter,
                updateStartDateFilter,
                updateEndDateFilter,
                resetState,
            }}>
            {children}
        </SolutionsFiltersContext.Provider>
    )
}
