import { createContext } from 'react'
import { DistributorsFiltersState } from '.'

interface ContextProps {
    distributorsFilters: DistributorsFiltersState

    updateSearchFilter: (value) => void

    resetState: () => void
}

export const DistributorsFiltersContext = createContext({} as ContextProps)
