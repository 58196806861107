import { CampaignsFiltersState } from '.'

type FiltersActionType =
    | {
          type: '[CAMPAIGNS_FILTERS] UPDATE_SEARCH'
          payload: { search: string }
      }
    | { type: '[CAMPAIGNS_FILTERS] RESET_STATE' }

export const campaignsFiltersReducer = (
    state: CampaignsFiltersState,
    action: FiltersActionType,
): CampaignsFiltersState => {
    switch (action.type) {
        case '[CAMPAIGNS_FILTERS] UPDATE_SEARCH': {
            return {
                ...state,
                search: action.payload.search,
            }
        }

        case '[CAMPAIGNS_FILTERS] RESET_STATE': {
            return {
                search: '',
            }
        }

        default:
            return state
    }
}
