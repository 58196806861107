import React, { FC, useReducer, ReactNode, useEffect } from 'react'

import { FiltersContext, filtersReducer } from './'

export interface FiltersState {
    search: string
    status: string
    start_date: string
    end_date: string
    commercial_id: string
    distributor_id: string
    opportunity_type: string
}

interface Props {
    children: ReactNode
}

const Filters_INITIAL_STATE: FiltersState = {
    search: '',
    status: '',
    start_date: '',
    end_date: '',
    commercial_id: null,
    distributor_id: null,
    opportunity_type: null,
}

export const FiltersProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(filtersReducer, Filters_INITIAL_STATE)

    const updateSearchFilter = (value: string) => {
        dispatch({
            type: '[FILTERS] UPDATE_SEARCH',
            payload: { search: value },
        })
    }

    const updateStatusFilter = (value: string) => {
        dispatch({
            type: '[FILTERS] UPDATE_STATUS',
            payload: { status: value },
        })
    }

    const updateDistributorFilter = (value: string) => {
        dispatch({
            type: '[FILTERS] UPDATE_DISTRIBUTOR_ID',
            payload: { distributor_id: value },
        })
    }

    const updateCommercialFilter = (value: string) => {
        dispatch({
            type: '[FILTERS] UPDATE_COMMERCIAL_ID',
            payload: { commercial_id: value },
        })
    }

    const updateStartDateFilter = (value: string) => {
        dispatch({
            type: '[FILTERS] UPDATE_START_DATE',
            payload: { start_date: value },
        })
    }

    const updateEndDateFilter = (value: string) => {
        dispatch({
            type: '[FILTERS] UPDATE_END_DATE',
            payload: { end_date: value },
        })
    }

    const updateOppotunityTypeFilter = (value: string) => {
        dispatch({
            type: '[FILTERS] UPDATE_OPPORTUNITY_TYPE',
            payload: value,
        })
    }

    const resetState = () => {
        dispatch({
            type: '[FILTERS] RESET_STATE',
        })
    }

    return (
        <FiltersContext.Provider
            value={{
                filters: { ...state },

                // Methods
                updateSearchFilter,
                updateStatusFilter,
                updateDistributorFilter,
                updateCommercialFilter,
                updateStartDateFilter,
                updateEndDateFilter,
                updateOppotunityTypeFilter,
                resetState,
            }}>
            {children}
        </FiltersContext.Provider>
    )
}
