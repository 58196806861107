import React, { FC, useReducer, ReactNode, useEffect } from 'react'

import { TaskFiltersContext, taskFiltersReducer } from './'

export interface TaskFiltersState {
    responsible_id: string
    end_date: string
    start_date: string
}

interface Props {
    children: ReactNode
}

const TaskFilters_INITIAL_STATE: TaskFiltersState = {
    responsible_id: null,
    start_date: '',
    end_date: '',
}

export const TaskFiltersProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(
        taskFiltersReducer,
        TaskFilters_INITIAL_STATE,
    )

    const updateResponsibleFilter = (value: string) => {
        dispatch({
            type: '[TASK_FILTERS] UPDATE_RESPONSIBLE_ID',
            payload: { responsible_id: value },
        })
    }

    const updateStartDateFilter = (value: string) => {
        dispatch({
            type: '[TASK_FILTERS] UPDATE_START_DATE',
            payload: { start_date: value },
        })
    }

    const updateEndDateFilter = (value: string) => {
        dispatch({
            type: '[TASK_FILTERS] UPDATE_END_DATE',
            payload: { end_date: value },
        })
    }

    const resetState = () => {
        dispatch({
            type: '[TASK_FILTERS] RESET_STATE',
        })
    }

    return (
        <TaskFiltersContext.Provider
            value={{
                taskFilters: { ...state },

                // Methods
                updateResponsibleFilter,
                updateStartDateFilter,
                updateEndDateFilter,
                resetState,
            }}>
            {children}
        </TaskFiltersContext.Provider>
    )
}
