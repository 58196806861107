import React, { FC, useReducer, ReactNode } from 'react'

import { BonusFiltersContext, bonusFiltersReducer } from './'

export interface BonusFiltersState {
    search: string
    status: string
    representative_volunteer_id: string
    commercial_id: string
    supervisor_id: string
    distributor_id: string
    end_date: string
    start_date: string
    available: number
}

interface Props {
    children: ReactNode
}

const BonusFilters_INITIAL_STATE: BonusFiltersState = {
    search: '',
    status: '',
    representative_volunteer_id: null,
    commercial_id: null,
    supervisor_id: null,
    distributor_id: null,
    start_date: '',
    end_date: '',
    available: null,
}

export const BonusFiltersProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(
        bonusFiltersReducer,
        BonusFilters_INITIAL_STATE,
    )

    const updateSearchFilter = (value: string) => {
        dispatch({
            type: '[BONUS_FILTERS] UPDATE_SEARCH',
            payload: { search: value },
        })
    }

    const updateStatusFilter = (value: string) => {
        dispatch({
            type: '[BONUS_FILTERS] UPDATE_STATUS',
            payload: { status: value },
        })
    }

    const updateRepresentativeFilter = (value: string) => {
        dispatch({
            type: '[BONUS_FILTERS] REPRESENTATIVE_VOLUNTEER_ID',
            payload: { representative_volunteer_id: value },
        })
    }

    const updateDistributorFilter = (value: string) => {
        dispatch({
            type: '[BONUS_FILTERS] UPDATE_DISTRIBUTOR_ID',
            payload: { distributor_id: value },
        })
    }

    const updateCommercialFilter = (value: string) => {
        dispatch({
            type: '[BONUS_FILTERS] UPDATE_COMMERCIAL_ID',
            payload: { commercial_id: value },
        })
    }

    const updateSupervisorFilter = (value: string) => {
        dispatch({
            type: '[BONUS_FILTERS] UPDATE_SUPERVISOR_ID',
            payload: { supervisor_id: value },
        })
    }

    const updateStartDateFilter = (value: string) => {
        dispatch({
            type: '[BONUS_FILTERS] UPDATE_START_DATE',
            payload: { start_date: value },
        })
    }

    const updateEndDateFilter = (value: string) => {
        dispatch({
            type: '[BONUS_FILTERS] UPDATE_END_DATE',
            payload: { end_date: value },
        })
    }

    const updateAvailableFilter = (value: number) => {
        dispatch({
            type: '[BONUS_FILTERS] UPDATE_AVAILABLE',
            payload: { available: value },
        })
    }

    const resetState = () => {
        dispatch({
            type: '[BONUS_FILTERS] RESET_STATE',
        })
    }

    return (
        <BonusFiltersContext.Provider
            value={{
                bonusFilters: { ...state },

                // Methods
                updateSearchFilter,
                updateStatusFilter,
                updateRepresentativeFilter,
                updateSupervisorFilter,
                updateDistributorFilter,
                updateCommercialFilter,
                updateStartDateFilter,
                updateEndDateFilter,
                updateAvailableFilter,
                resetState,
            }}>
            {children}
        </BonusFiltersContext.Provider>
    )
}
