import { TaskFiltersState } from './'

type FiltersActionType =
    | {
          type: '[TASK_FILTERS] UPDATE_RESPONSIBLE_ID'
          payload: { responsible_id: string }
      }
    | {
          type: '[TASK_FILTERS] UPDATE_START_DATE'
          payload: { start_date: string }
      }
    | { type: '[TASK_FILTERS] UPDATE_END_DATE'; payload: { end_date: string } }
    | { type: '[TASK_FILTERS] RESET_STATE' }

export const taskFiltersReducer = (
    state: TaskFiltersState,
    action: FiltersActionType,
): TaskFiltersState => {
    switch (action.type) {
        case '[TASK_FILTERS] UPDATE_RESPONSIBLE_ID': {
            return {
                ...state,
                responsible_id: action.payload.responsible_id,
            }
        }

        case '[TASK_FILTERS] UPDATE_START_DATE': {
            return {
                ...state,
                start_date: action.payload.start_date,
            }
        }

        case '[TASK_FILTERS] UPDATE_END_DATE': {
            return {
                ...state,
                end_date: action.payload.end_date,
            }
        }

        case '[TASK_FILTERS] RESET_STATE': {
            return {
                responsible_id: '',
                end_date: '',
                start_date: '',
            }
        }

        default:
            return state
    }
}
