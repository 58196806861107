import useSWR from 'swr'
import axios from '@/lib/axios'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { User } from '@/interfaces/user'
import { PROTECTED_ROUTES } from '@/utils/routes'

interface Props {
    middleware?: string
    redirectIfAuthenticated?: string
}

export const useAuth = ({
    middleware,
    redirectIfAuthenticated,
}: Props = {}) => {
    const router = useRouter()

    const { data: user, error, mutate } = useSWR<User>(
        '/api/user/get-auth-user',
        url => {
            if (
                router?.pathname !== '/login' &&
                !PROTECTED_ROUTES.includes(router?.pathname)
            ) {
                return null
            }

            if (user) return user

            return axios
                .get(url)
                .then(res => res.data.data)
                .catch(error => {
                    if (error.response.status !== 409) throw error

                    router.push('/verify-email')
                })
        },
    )

    const csrf = () => axios.get(`/sanctum/csrf-cookie`)

    const register = async ({ setErrors, ...props }) => {
        await csrf()

        setErrors([])

        axios
            .post('/register', props)
            .then(() => mutate())
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const login = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/api/login', props)
            .then(res => {
                mutate()

                if (res.data.two_factor) {
                    router.push('/two-factor-challenge')
                } else {
                    router.push(redirectIfAuthenticated)
                }
            })
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const forgotPassword = async ({ setErrors, setStatus, email, setSend }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/api/forgot-password', { email })
            .then(response => {
                setSend(true)
                return setStatus(response.data.status)
            })
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resetPassword = async ({
        setErrors,
        setStatus,
        setIsRecovered,
        password,
        password_confirmation,
    }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        if (typeof router.query.token === 'string') {
            const tokenSplit = router.query.token.split('&')
            const token = tokenSplit[0]
                ? tokenSplit[0].split('token=').join('')
                : ''
            const email = tokenSplit[1]
                ? tokenSplit[1].split('email=').join('')
                : ''

            return axios
                .post('/api/reset-password', {
                    token,
                    password,
                    password_confirmation,
                    email,
                })
                .then(response => setIsRecovered(true))
                .catch(error => {
                    throw new Error(
                        '¡Lo sentimos, ha ocurrido un error inesperado!',
                    )
                })
        } else {
            throw new Error('El token es invalido')
        }
    }

    const resendEmailVerification = ({ setStatus }) => {
        axios
            .post('/api/email/verification-notification')
            .then(response => setStatus(response.data.status))
    }

    const twoFactorChallenge = async ({ code, setErrors, setStatus }) => {
        setErrors([])
        setStatus(null)

        return axios
            .post('/api/two-factor-challenge', {
                code,
            })
            .then(() => {
                mutate()
                router.push(redirectIfAuthenticated)
            })
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(
                    'El código de autenticación de dos factores proporcionado no es válido.',
                )
            })
    }

    const logout = React.useCallback(async () => {
        if (!error) {
            await axios.post('/api/logout').then(() => mutate())
        }

        window.location.pathname = '/login'
    }, [error, mutate])

    useEffect(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user)
            router.push(redirectIfAuthenticated)
        if (middleware === 'auth' && error) logout()
    }, [user, error, middleware, redirectIfAuthenticated, router, logout])

    return {
        user,
        isLoading: !user && !error,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
        twoFactorChallenge,
    }
}
