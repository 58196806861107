import React, { FC, useReducer, ReactNode } from 'react'

import { CampaignsFiltersContext, campaignsFiltersReducer } from '.'

export interface CampaignsFiltersState {
    search: string
}

interface Props {
    children: ReactNode
}

const CampaignsFilters_INITIAL_STATE: CampaignsFiltersState = {
    search: '',
}

export const CampaignsFiltersProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(
        campaignsFiltersReducer,
        CampaignsFilters_INITIAL_STATE,
    )

    const updateSearchFilter = (value: string) => {
        dispatch({
            type: '[CAMPAIGNS_FILTERS] UPDATE_SEARCH',
            payload: { search: value },
        })
    }

    const resetState = () => {
        dispatch({
            type: '[CAMPAIGNS_FILTERS] RESET_STATE',
        })
    }

    return (
        <CampaignsFiltersContext.Provider
            value={{
                campaignsFilters: { ...state },

                // Methods
                updateSearchFilter,
                resetState,
            }}>
            {children}
        </CampaignsFiltersContext.Provider>
    )
}
