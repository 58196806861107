import { createContext } from 'react'
import { SolutionsFiltersState } from './SolutionsFiltersProvider'

interface ContextProps {
    solutionsFilters: SolutionsFiltersState

    updateSearchFilter: (value) => void
    updateStatusFilter: (value) => void
    updateStatusImplementationFilter: (value) => void
    updateStartDateFilter: (value) => void
    updateEndDateFilter: (value) => void
    resetState: () => void
}

export const SolutionsFiltersContext = createContext({} as ContextProps)
