import { extendTheme } from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { StepsStyleConfig } from 'chakra-ui-steps'
import Button from './components/button'

export default extendTheme({
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    colors: {
        ...colors,
        linikit: {
            principal: '#2F59A5',
            secondary: '#8C3D85',
            gray: '#676767',
            price: '#50D2D9',
            footer: '#FBFBFB',
            darkPrincipal: '#143E98',
            'hero-blue': '#1C2351',
            'footer-font': '#515151',
            'footer-blue': '#053F80',
        },
        devices: {
            gray: {
                50: '#9A9C9F40',
                100: '#E9EBED',
                125: '#EFF8FF',
                150: '#EAEBF0',
                175: '#ECF2F8',
                200: '#DBE7F0',
                300: '#64737F',
                400: '#7B8499',
                700: '#4f4f4f',
                800: '#25262F',
                900: '#17181E',
            },
            blue: {
                25: '#282C3F1F',
                50: '#1174D0',
                75: '#2196F3',
                100: '#20274A',
                200: '#293C8E',
                300: '#282C3F',
                400: '#0E1430',
            },
            purple: {
                100: '#2A204A',
            },
        },
        danger: {
            700: '#b91c1c',
        },
    },
    components: {
        Button,
        Steps: StepsStyleConfig,
        Switch: {
            variants: {
                login: {
                    size: 'lg',
                },
            },
            defaultProps: {
                size: 'md', // default is md
                colorScheme: colors['primary-outline-border'], // default is gray
            },
        },
    },
    breakpoints: {
        xs: '300px',
        sm: '375px',
        md: '768px',
        lg: '960px',
        xl: '1200px',
        '2xl': '1536px',
    },
})
