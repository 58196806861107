import { BonusFiltersState } from './'

type FiltersActionType =
    | { type: '[BONUS_FILTERS] UPDATE_SEARCH'; payload: { search: string } }
    | { type: '[BONUS_FILTERS] UPDATE_STATUS'; payload: { status: string } }
    | {
          type: '[BONUS_FILTERS] REPRESENTATIVE_VOLUNTEER_ID'
          payload: { representative_volunteer_id: string }
      }
    | {
          type: '[BONUS_FILTERS] UPDATE_COMMERCIAL_ID'
          payload: { commercial_id: string }
      }
    | {
          type: '[BONUS_FILTERS] UPDATE_SUPERVISOR_ID'
          payload: { supervisor_id: string }
      }
    | {
          type: '[BONUS_FILTERS] UPDATE_DISTRIBUTOR_ID'
          payload: { distributor_id: string }
      }
    | {
          type: '[BONUS_FILTERS] UPDATE_START_DATE'
          payload: { start_date: string }
      }
    | { type: '[BONUS_FILTERS] UPDATE_END_DATE'; payload: { end_date: string } }
    | {
          type: '[BONUS_FILTERS] UPDATE_AVAILABLE'
          payload: { available: number }
      }
    | { type: '[BONUS_FILTERS] RESET_STATE' }

export const bonusFiltersReducer = (
    state: BonusFiltersState,
    action: FiltersActionType,
): BonusFiltersState => {
    switch (action.type) {
        case '[BONUS_FILTERS] UPDATE_SEARCH': {
            return {
                ...state,
                search: action.payload.search,
            }
        }

        case '[BONUS_FILTERS] UPDATE_STATUS': {
            return {
                ...state,
                status: action.payload.status,
            }
        }

        case '[BONUS_FILTERS] REPRESENTATIVE_VOLUNTEER_ID': {
            return {
                ...state,
                representative_volunteer_id:
                    action.payload.representative_volunteer_id,
            }
        }

        case '[BONUS_FILTERS] UPDATE_COMMERCIAL_ID': {
            return {
                ...state,
                commercial_id: action.payload.commercial_id,
            }
        }

        case '[BONUS_FILTERS] UPDATE_SUPERVISOR_ID': {
            return {
                ...state,
                supervisor_id: action.payload.supervisor_id,
            }
        }

        case '[BONUS_FILTERS] UPDATE_DISTRIBUTOR_ID': {
            return {
                ...state,
                distributor_id: action.payload.distributor_id,
            }
        }

        case '[BONUS_FILTERS] UPDATE_START_DATE': {
            return {
                ...state,
                start_date: action.payload.start_date,
            }
        }

        case '[BONUS_FILTERS] UPDATE_END_DATE': {
            return {
                ...state,
                end_date: action.payload.end_date,
            }
        }

        case '[BONUS_FILTERS] UPDATE_AVAILABLE': {
            return {
                ...state,
                available: action.payload.available,
            }
        }

        case '[BONUS_FILTERS] RESET_STATE': {
            return {
                search: '',
                representative_volunteer_id: '',
                commercial_id: '',
                supervisor_id: '',
                distributor_id: '',
                status: '',
                end_date: '',
                start_date: '',
                available: null,
            }
        }

        default:
            return state
    }
}
