import React from 'react'

import { Box, Image } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { colors } from '@/utils/const'

export const AtriniumLoader = () => {
    return (
        <Box
            w="full"
            bg={colors.primary}
            h="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <motion.div
                transition={{
                    repeat: Infinity,
                }}
                animate={{
                    scale: [0.5, 0.55, 0.5],
                }}>
                <Image src="/images/FullWhiteLogo.png" alt="Logo" />
            </motion.div>
        </Box>
    )
}
