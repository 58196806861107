import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useAuth } from '@/hooks/auth'
import { AtriniumLoader } from './AtriniumLoader'

export default function PrivateRoute({ protectedRoutes, children }) {
    const router = useRouter()
    const { user, isLoading } = useAuth()

    const pathIsProtected = protectedRoutes.indexOf(router.pathname) !== -1

    useEffect(() => {
        if (!isLoading && !user && pathIsProtected) {
            router.push('/login')
        }
    }, [isLoading, user, pathIsProtected])

    if ((isLoading || !user) && pathIsProtected) {
        return <AtriniumLoader />
    }

    return children
}
