import React, { FC, useReducer, ReactNode } from 'react'

import { ClientsFiltersContext, clientsFiltersReducer } from '.'

export interface ClientsFiltersState {
    search: string
}

interface Props {
    children: ReactNode
}

const ClientsFilters_INITIAL_STATE: ClientsFiltersState = {
    search: '',
}

export const ClientsFiltersProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(
        clientsFiltersReducer,
        ClientsFilters_INITIAL_STATE,
    )

    const updateSearchFilter = (value: string) => {
        dispatch({
            type: '[CLIENTS_FILTERS] UPDATE_SEARCH',
            payload: { search: value },
        })
    }

    return (
        <ClientsFiltersContext.Provider
            value={{
                clientsFilters: { ...state },

                // Methods
                updateSearchFilter,
            }}>
            {children}
        </ClientsFiltersContext.Provider>
    )
}
