import { createContext } from 'react'
import { ClientsFiltersState } from './ClientsFiltersProvider'

interface ContextProps {
    clientsFilters: ClientsFiltersState

    updateSearchFilter: (value) => void
}

export const ClientsFiltersContext = createContext({} as ContextProps)
