import React, { FC, useReducer, ReactNode } from 'react'

import { RemittancesFiltersContext, remittancesFiltersReducer } from './'

export interface RemittancesFiltersState {
    search: string
    status: string
    start_date: string
    end_date: string
}

interface Props {
    children: ReactNode
}

const RemittancesFilters_INITIAL_STATE: RemittancesFiltersState = {
    search: '',
    status: '',
    start_date: '',
    end_date: '',
}

export const RemittancesFiltersProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(
        remittancesFiltersReducer,
        RemittancesFilters_INITIAL_STATE,
    )

    const updateSearchFilter = (value: string) => {
        dispatch({
            type: '[REMITTANCES_FILTERS] UPDATE_SEARCH',
            payload: { search: value },
        })
    }

    const updateStatusFilter = (value: string) => {
        dispatch({
            type: '[REMITTANCES_FILTERS] UPDATE_STATUS',
            payload: { status: value },
        })
    }

    const updateStartDateFilter = (value: string) => {
        dispatch({
            type: '[REMITTANCES_FILTERS] UPDATE_START_DATE',
            payload: { start_date: value },
        })
    }

    const updateEndDateFilter = (value: string) => {
        dispatch({
            type: '[REMITTANCES_FILTERS] UPDATE_END_DATE',
            payload: { end_date: value },
        })
    }

    const resetState = () => {
        dispatch({
            type: '[REMITTANCES_FILTERS] RESET_STATE',
        })
    }

    return (
        <RemittancesFiltersContext.Provider
            value={{
                remittancesFilters: { ...state },

                // Methods
                updateSearchFilter,
                updateStatusFilter,
                updateStartDateFilter,
                updateEndDateFilter,
                resetState,
            }}>
            {children}
        </RemittancesFiltersContext.Provider>
    )
}
