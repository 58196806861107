import { DistributorsFiltersState } from '.'

type FiltersActionType =
    | {
          type: '[DISTRIBUTORS_FILTERS] UPDATE_SEARCH'
          payload: { search: string }
      }
    | { type: '[DISTRIBUTORS_FILTERS] RESET_STATE' }

export const distributorsFiltersReducer = (
    state: DistributorsFiltersState,
    action: FiltersActionType,
): DistributorsFiltersState => {
    switch (action.type) {
        case '[DISTRIBUTORS_FILTERS] UPDATE_SEARCH': {
            return {
                ...state,
                search: action.payload.search,
            }
        }

        case '[DISTRIBUTORS_FILTERS] RESET_STATE': {
            return {
                search: '',
            }
        }

        default:
            return state
    }
}
