const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
    content: ['./src/**/*.{js,jsx,ts,tsx}'],
    darkMode: 'media',
    theme: {
        extend: {
            colors: {
                // Primary
                primary: '#091D70',
                'primary-outline-border': 'rgba(9, 29, 112, 0.16)',
                'primary-soft-focus': 'rgba(9, 29, 112, 0.12)',
                'primary-soft-hover': 'rgba(9, 29, 112, 0.08)',
                'primary-soft-default': 'rgba(9, 29, 112, 0.04)',

                // Secondary
                secondary: '#2463EB',
                'secondary-outline-border': 'rgba(36, 99, 235, 0.16)',
                'secondary-soft-focus': 'rgba(36, 99, 235, 0.12)',
                'secondary-soft-hover': 'rgba(36, 99, 235, 0.08)',
                'secondary-soft-default': 'rgba(36, 99, 235, 0.04)',

                // Tertiary
                tertiary: '#00ACFF',
                // Quaternary
                quaternary: '#89CFF5',

                // Alternatives
                'warning-high': 'rgba(253, 124, 4, 1)',
                'warning-medium': 'rgba(253, 124, 4, 0.35)',
                'warning-low': 'rgba(253, 124, 4, 0.15)',
                'success-high': 'rgba(26, 192, 87, 1)',
                'success-medium': 'rgba(26, 192, 87 , 0.35)',
                'success-low': 'rgba(26, 192, 87, 0.15)',
                'danger-high': 'rgba(239, 68, 68, 1)',
                'danger-medium': 'rgba(239, 68, 68, 0.35)',
                'danger-low': 'rgba(239, 68, 68, 0.15)',
                'info-high': 'rgba(6, 182, 212, 1)',
                'info-medium': 'rgba(6, 182, 212, 0.35)',
                'info-low': 'rgba(6, 182, 212, 0.15)',
                
                // Black 
                'black-letter': '#090A0A',
                'black-medium': 'rgba(0, 0, 0, 0.5)',
                'black-soft': 'rgba(0, 0, 0, 0.3)',
                
                // Whites
                'white': '#FFFFFF',
                'white-secondary': '#E5E5E5',
                
                // Grdients
                'gradient-s': '#103074',
                'gradient-e': '#001647',

                // Gray
                'gray-primary': 'rgba(134, 146, 166, 1)',
                'gray-primary-30': 'rgba(134, 146, 166, 0.3)',
                'gray-soft-30': 'rgba(217, 217, 217, 0.3)',

                // Data landing colors
                'data-principal': '#E8F4FA',
                'data-text-principal': '#1090CB',
                'data-text-gray': '#585858',
                'data-button-principal': '#053F80'
            },
            fontFamily: {
                sans: ['Inter', ...defaultTheme.fontFamily.sans],
            },
            fontSize: {
                'xx-large': '28px',
                'x-large': '22px',
                '32xl': ['32px', '39px'],
                '24xl': ['24px', '29px'],
                '22xl': ['22px', '24px'],
                '20xl': ['20px', '24.2px'],
                '16xl': ['16px', '19px'],
                '14xl': ['14px', '17px'],
                large: '18px',
                medium: '16px',
                normal: '12px',
                small: '10px',
            },
            fontWeight: {
                'semi-light': 400,
                'semi-bold': 600,
                bold: 700,
            },
            screens: {
                mobile: '480px',
                tablet: '640px',
                desktop: '1024px',
            },
            spacing: {
                '428': '428px',
                '398': '398px',
                '380': '380px',
                '350': '350px',
                '70': '70px'
              }
        },
    },
    variants: {
        extend: {
            opacity: ['disabled'],
        },
    },
    plugins: [require('@tailwindcss/forms')],
}
