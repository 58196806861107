import { createContext } from 'react'
import { TaskFiltersState } from './TaskFiltersProvider'

interface ContextProps {
    taskFilters: TaskFiltersState

    updateResponsibleFilter: (value) => void
    updateStartDateFilter: (value) => void
    updateEndDateFilter: (value) => void
    resetState: () => void
}

export const TaskFiltersContext = createContext({} as ContextProps)
