import { createContext } from 'react'
import { CampaignsFiltersState } from '.'

interface ContextProps {
    campaignsFilters: CampaignsFiltersState

    updateSearchFilter: (value) => void

    resetState: () => void
}

export const CampaignsFiltersContext = createContext({} as ContextProps)
